import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import Inputselect from "../../../components/inputselect/inputselect";
import {
  addServiceToCart,
  getProviders,
  getProviderServiceCharge,
  getProviderServices,
  getProviderServiceThreshold,
  submitLoanRequest,
} from "../../../api/base/base";
import {
  commaNumber,
  convertToSlug,
  openNotificationWithIcon,
  transformCurrencyToSymbol,
  validateNumberAbove,
} from "../../../utils/helper";
import { IObjectKeysStringsOnly } from "../../../utils/helpers-interface";
import Skeletonloader from "../../../components/skeletonloader/skeletonloader";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Uploadsignaturemodal from "../../../components/uploadsignaturemodal/uploadsignaturemodal";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import {
  directLoanOffer,
  getLoanTypes,
  loanOffer,
} from "../../../api/loan/loan";
import Inputwithaddon from "../../../components/inputwithaddon/inputwithaddon";
import UploadRequestInfo from "./uploadRequestInfo";
import Inputamount from "../../../components/inputamount/inputamount";
import moment from "moment";

const installmentsCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const navlist = [
  {
    name: "Fair Lending Policy",
    text: "Kiowope Fair Lending Policy",
    link: "/dashboard/documents",
    docLink: "/docs/Fair-Lending-Policy.pdf",
  },
  {
    name: "Privacy Policy",
    text: "Kiowope Privacy Policy",
    link: "/dashboard/documents",
    docLink: "/docs/Privacy-Policy.pdf",
  },
  {
    name: "Terms of  Use",
    text: "Kiowope Terms of  Use",
    link: "/dashboard/documents",
    docLink: "/docs/Terms-of-Use.pdf",
  },
  {
    name: "eSign Consent ",
    text: "Your Consent to receive vital documentation via electronic means",
    link: "/dashboard/documents",
    docLink: "/docs/eSig-Consent.pdf",
  },
];

const tenure = [
  {
    id: 1,
    name: "1 month",
  },
  {
    id: 2,
    name: "2 months",
  },
  {
    id: 3,
    name: "3 months",
  },
  {
    id: 4,
    name: "4 months",
  },
  {
    id: 5,
    name: "5 months",
  },
  {
    id: 6,
    name: "6 months",
  },
];

const Requestloan = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [amount, setAmount] = useState<number | null>(null);
  const [savedRequest, setSavedRequest] = useState<any>({});
  const [submitting, setSubmitting] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [loanInformation, setLoanInformation] = useState<JSX.Element | null>(
    null
  );
  const [errorData, setErrorData] = useState<{
    min_duration: string;
    max_duration: string;
    min_amount: string;
    max_amount: string;
    request: {
      duration: string;
      amount: string;
      interest_rate: number;
      interest_amount: number;
      repayment_amount: number;
      repayment_date: string;
      instalment: {
        status: "Allowed" | "Not Allowed";
        count: number;
        duration: string;
      };
    };
  } | null>(null);

  const [currentLoanType, setCurrentLoanType] = useState<{
    min_duration: string;
    max_duration: string;
    min_amount: string;
    max_amount: string;
    id: string;
  } | null>(null);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentDoc, setCurrentDoc] = useState("");
  const [visibleUploadSignature, setVisibleUploadSignature] = useState(false);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  // const [loadingServices, setLoadingServices] = useState(false);
  // const [services, setServices] = useState([]);

  const [loadingLoanTypes, setLoadingLoanTypes] = useState(false);
  const [loanTypes, setLoanTypes] = useState([]);

  // const [loadingThreshold, setLoadingThreshold] = useState(false);
  // const [threshold, setThreshold] = useState<string[]>([]);

  const [requestInfo, setRequestInfo] = useState<
    {
      name: string;
      type:
        | "select"
        | "text"
        | "number"
        | "alphanumeric"
        | "audio"
        | "video"
        | "pdf"
        | "image";
      option: string[];
      required: string;
      value: string;
    }[]
  >([]);

  const closeModal = () => {
    setVisibleUploadSignature(false);
  };

  const [loadingCharge, setLoadingCharge] = useState(false);
  const [charge, setCharge] = useState<{
    full_amount: number;
    interest: number;
    repayment_amount: number;
  } | null>(null);

  const [errors, setErrors] = useState({
    loanType: "",
    amount: "",
    duration: "",
    count: "",
  });

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
    token: state.user.token,
  }));

  const { user, token } = state;

  const fetchLoanTypes = async () => {
    setLoadingLoanTypes(true);

    try {
      const res = await getLoanTypes({
        currency: "ngn",
        type: "DIRECT",
      });
      const { status, data } = res.data;

      if (status === "success") {
        if (data !== null) {
          setLoanTypes(data);
        }
      } else {
        message.warning(`Get Loan Types, ${res.data.message}`);
      }

      setLoadingLoanTypes(false);
    } catch (error) {
      setLoadingLoanTypes(false);
      message.error("Something went wrong: Get Loan Type");
    }
  };

  useEffect(() => {
    fetchLoanTypes();
    // eslint-disable-next-line
  }, []);

  // const fetchServices = async (provider_id: string) => {
  //   setLoadingServices(true);

  //   try {
  //     const res = await getProviderServices({
  //       provider_id,
  //     });
  //     const { status, data } = res.data;

  //     if (status === "success") {
  //       if (data !== null) {
  //         setServices(data);
  //       }
  //     } else {
  //       message.warning(`Get services, ${res.data.message}`);
  //     }

  //     setLoadingServices(false);
  //   } catch (error) {
  //     setLoadingServices(false);
  //     message.error("Something went wrong: Get services");
  //   }
  // };

  // const fetchServiceThreshold = async (product_id: string) => {
  //   setLoadingThreshold(true);

  //   try {
  //     const res = await getProviderServiceThreshold({
  //       product_id,
  //     });
  //     const { status, data } = res.data;

  //     let newThreshold: string[] = [];

  //     if (status === "success") {
  //       if (data !== null) {
  //         let dataSource: IObjectKeysStringsOnly = data;
  //         Object.entries(dataSource).forEach(([key, value]) => {
  //           newThreshold.push(
  //             `${key.replace(/_/g, " ").toUpperCase()}- ₦ ${commaNumber(value)}`
  //           );
  //         });
  //         setThreshold(newThreshold);
  //       }
  //     } else {
  //       message.warning(`Get threshold, ${res.data.message}`);
  //     }

  //     setLoadingThreshold(false);
  //   } catch (error) {
  //     setLoadingThreshold(false);
  //     message.error("Something went wrong: Get threshold");
  //   }
  // };

  // const fetchProviderCharge = async (
  //   product_id: string,
  //   charge_id: string,
  //   threshold: string
  // ) => {
  //   setLoadingCharge(true);
  //   setCharge(null);

  //   try {
  //     const res = await getProviderServiceCharge({
  //       product_id,
  //       charge_id,
  //       threshold: threshold.split("-")[0].toLowerCase().replace(/ /g, "_"),
  //     });
  //     const { status, data } = res.data;

  //     if (status === "success") {
  //       if (data !== null) {
  //         setCharge(data);
  //       }
  //     } else {
  //       message.warning(`Get provider charge, ${res.data.message}`);
  //     }

  //     setLoadingCharge(false);
  //   } catch (error) {
  //     setLoadingCharge(false);
  //     message.error("Something went wrong: Get privider charge");
  //   }
  // };

  const submitOffer = async () => {
    try {
      setSubmitting(true);
      const res = await directLoanOffer({
        ...savedRequest,
      });

      const { status, data } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Submit Loan", res.data.message);

        navigate("/dashboard/request-loan-success");
      } else {
        openNotificationWithIcon("warning", "Submit Loan", res.data.message);
      }
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      // setLoadingServices(false);
      message.error("Something went wrong: Submit loan");
    }
  };

  const onFinish = async (values: any) => {
    // const { service, repaymentTenure, loanPayment } = values;

    setError(null);
    setErrorData(null);
    setLoanInformation(null);
    setShowSummary(false);

    setLoading(true);
    const { loanType, amount, duration, count, ...rest } = values;
    const request_info: {
      name: string;
      input: string;
    }[] = [];

    const resting: IObjectKeysStringsOnly = rest;

    Object.entries(resting).forEach(([key, value]) => {
      request_info.push({
        name: key?.split("-").join(" "),
        input: value,
      });
    });

    const newErrors = { ...errors };

    newErrors["amount"] = validateNumberAbove(amount, 0, "amount");
    newErrors["duration"] = validateNumberAbove(duration, 0, "duration");

    if (currentLoanType?.max_amount !== currentLoanType?.min_amount) {
      if (Number(amount) < Number(currentLoanType?.min_amount)) {
        newErrors["amount"] = `Min amount is ₦${commaNumber(
          Number(currentLoanType?.min_amount)
        )}`;
      }
      if (Number(amount) > Number(currentLoanType?.max_amount)) {
        newErrors["amount"] = `Max amount is ₦${commaNumber(
          Number(currentLoanType?.max_amount)
        )}`;
      }
    }

    newErrors["count"] = validateNumberAbove(count, 0, "installments");

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        setSavedRequest({
          currency: "ngn",
          id: loanType,
          amount,
          duration,
          request_info,
          count,
        });

        const res = await loanOffer({
          currency: "ngn",
          id: loanType,
          amount,
          duration,
          request_info,
          count,
        });

        const { status, data } = res.data;

        if (status === "success") {
          // openNotificationWithIcon(
          //   "success",
          //   "Submit Loan Offer",
          //   res.data.message
          // );

          if (data?.request?.instalment !== undefined) {
            if (data?.request?.instalment?.status !== "success") {
              const loanInformation = (
                <div>
                  Your request for loan amount <b>₦{commaNumber(amount)}</b> for{" "}
                  days
                  <b>
                    {count} installment
                    {count > 1 ? "s" : ""}{" "}
                  </b>{" "}
                  over a duration of{" "}
                  <b>
                    {duration} day
                    {duration > 1 ? "s" : ""}{" "}
                  </b>{" "}
                  is not allowed.{" "}
                </div>
              );
              setLoanInformation(loanInformation);
            } else {
              setShowSummary(true);
              setErrorData(data);
              // message.info(
              //   `Get loan offer: ${data?.request?.instalment?.status}`
              // );
            }
          }
          // navigate("/dashboard/request-loan-success");
        } else {
          // openNotificationWithIcon(
          //   "warning",
          //   "Submit Loan Offer",
          //   res.data.message
          // );
          setError(res.data.message);
          setErrorData(data);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        // setLoadingServices(false);
        message.error("Something went wrong: Submit loan offer");
      }
    } else {
      setLoading(false);
    }
  };

  const updateField = (name: string, value?: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    if (name === "loanType") {
      const filterRequestInfo: any[] = loanTypes.filter(
        (item: any) => item?.id === value
      );
      setCurrentLoanType(filterRequestInfo[0]);
      setRequestInfo(
        typeof filterRequestInfo[0]?.request_info === "string"
          ? []
          : filterRequestInfo[0]?.request_info
      );
    }

    // if (name === "provider") {
    //   fetchServices(value);
    // }

    // if (name === "service") {
    //   fetchServiceThreshold(value);
    // }
    // if (name === "repaymentTenure") {
    //   fetchProviderCharge(
    //     form.getFieldValue("service"),
    //     value,
    //     form.getFieldValue("loanPayment")
    //   );
    // }
  };

  const onFinishFailed = (errorInfo: any) => {
    errorInfo.errorFields.forEach((item: any) => {
      setErrors((prevState) => ({
        ...prevState,
        [item.name[0]]: item.errors.length ? item.errors[0] : "",
      }));
    });
  };

  // useEffect(() => {
  //   form.setFieldValue("service", undefined);
  //   form.setFieldValue("loanPayment", undefined);
  //   form.setFieldValue("repaymentTenure", undefined);
  //   setCharge(null);

  //   // eslint-disable-next-line
  // }, [form.getFieldValue("provider")]);

  // useEffect(() => {
  //   form.setFieldValue("loanPayment", undefined);
  //   form.setFieldValue("repaymentTenure", undefined);
  //   setCharge(null);

  //   // eslint-disable-next-line
  // }, [form.getFieldValue("service")]);

  // useEffect(() => {
  //   form.setFieldValue("repaymentTenure", undefined);
  //   setCharge(null);

  //   // eslint-disable-next-line
  // }, [form.getFieldValue("loanPayment")]);

  // useEffect(() => {
  //   setCharge(null);

  //   // eslint-disable-next-line
  // }, [form.getFieldValue("repaymentTenure")]);

  const constructFormInputs = () => {
    return (
      <>
        {requestInfo?.map((item, index) => (
          <Form.Item
            key={index}
            label={item.name}
            name={convertToSlug(item.name)}
            rules={[
              {
                required: item.required === "Yes",
                message: `${
                  item?.type === "select"
                    ? "Select"
                    : item?.type === "alphanumeric" ||
                      item?.type === "number" ||
                      item?.type === "text"
                    ? "Input"
                    : "Upload"
                } is required`,
              },
            ]}
          >
            {item.type === "select" && (
              <Inputselect
                placeHolder={`Select ${item.name}`}
                updateField={updateField}
                name={convertToSlug(item.name)}
                // icon={<img src="/img/vectors/hospital.svg" alt="hospital" />}
                selectOptions={item.option}
                optionHasValue
                loading={false}
                showSearch={false}
              />
            )}
            {item.type === "text" || item.type === "alphanumeric" ? (
              <Input
                name={convertToSlug(item.name)}
                placeholder={` ${item.name}`}
                onChange={(e: any) => {
                  updateField(convertToSlug(item.name), e.target.value);
                }}
              />
            ) : null}

            {item.type === "number" && (
              <Inputwithaddon
                updateField={updateField}
                name={convertToSlug(item.name)}
                placeHolder="8000000000"
                addonBefore="+234"
              />
            )}

            {item.type === "pdf" ||
            item.type === "image" ||
            item.type === "audio" ||
            item.type === "video" ? (
              <UploadRequestInfo
                token={token}
                type={item.type}
                name={convertToSlug(item.name)}
                submitting={loading}
                updateField={updateField}
                uploadName={item.name}
                setUploading={setUploading}
              />
            ) : null}
          </Form.Item>
        ))}
      </>
    );
  };

  useEffect(() => {
    if (currentLoanType !== null) {
      if (currentLoanType?.min_amount === currentLoanType?.max_amount) {
        form.setFieldValue("amount", currentLoanType?.max_amount);
        setAmount(Number(currentLoanType?.max_amount));
      }
    }
  }, [currentLoanType, form.getFieldValue("amount")]);

  return (
    <div className="mt-2">
      <div className="flex justify-center ">
        <img src="/img/logo/newlogo.svg" alt="logo" className="w-16" />
      </div>

      <div className="w-11/12 mx-auto lg:w-3/12">
        <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
          Request Loan
        </h1>

        {/* <p className="mb-10 text-sm font-normal font-poppins text-appcolorgrey ">
          You can apply for a loan.
        </p> */}

        {/* <p className="mb-10 text-sm font-normal font-poppins text-appcolorgrey ">
          <b>Beneficiary Name:</b>
          <br />
          {`${user?.firstname}  ${user?.lastname}`}
        </p> */}

        <div>
          {error != null && (
            <div className="mb-3">
              <Alert message={error} type="error" className="w-full" />
            </div>
          )}

          {loanInformation != null && (
            <div className="mb-3">
              <Alert
                message={loanInformation}
                type="warning"
                className="w-full"
              />
            </div>
          )}

          {errorData != null && (
            <div className="mb-3">
              <Alert
                message={
                  <div>
                    <div className="flex justify-between mb-4 font-poppins">
                      <p className="w-[40%] font-semibold"> Min Amount</p>
                      <p className="w-[60%] flex justify-end">
                        ₦{commaNumber(errorData?.min_amount)}
                      </p>
                    </div>

                    <div className="flex justify-between mb-4 font-poppins">
                      <p className="w-[40%] font-semibold"> Max Amount</p>
                      <p className="w-[60%] flex justify-end">
                        ₦{commaNumber(errorData?.max_amount)}
                      </p>
                    </div>

                    <div className="flex justify-between mb-4 font-poppins">
                      <p className="w-[40%] font-semibold"> Min Duration</p>
                      <p className="w-[60%] flex justify-end">
                        {commaNumber(errorData?.min_duration)} days
                      </p>
                    </div>

                    <div className="flex justify-between mb-4 font-poppins">
                      <p className="w-[40%] font-semibold"> Max Duration</p>
                      <p className="w-[60%] flex justify-end">
                        {commaNumber(errorData?.max_duration)} days
                      </p>
                    </div>
                  </div>
                }
                type="info"
                className="w-full"
              />
            </div>
          )}
        </div>

        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            validateStatus={errors?.loanType.length > 0 ? "error" : undefined}
            help={errors?.loanType.length > 0 ? errors.loanType : undefined}
            label="Loan Type"
            name="loanType"
            rules={[
              {
                required: true,
                message: "Loan Type is required",
              },
            ]}
          >
            <Inputselect
              placeHolder="Select Loan Type"
              updateField={updateField}
              name={"loanType"}
              // icon={<img src="/img/vectors/hospital.svg" alt="hospital" />}
              selectOptions={loanTypes}
              alternativeValue={"id"}
              selectOptionLabel="name"
              loading={loadingLoanTypes}
              showSearch={true}
            />
          </Form.Item>

          {currentLoanType !== null && (
            <>
              <Form.Item
                validateStatus={errors?.amount.length > 0 ? "error" : undefined}
                help={errors?.amount.length > 0 ? errors.amount : undefined}
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Amount is required",
                  },
                ]}
              >
                <Inputamount
                  isWholeNumber={true}
                  name="amount"
                  updateField={updateField}
                  placeHolder="Enter amount"
                  currency={"₦"}
                  amountValue={
                    currentLoanType?.min_amount === currentLoanType?.max_amount
                      ? amount?.toString()
                      : undefined
                  }
                  disabled={
                    currentLoanType?.min_amount === currentLoanType?.max_amount
                  }
                />
              </Form.Item>
            </>
          )}

          {/* <Form.Item
            validateStatus={errors?.amount.length > 0 ? "error" : undefined}
            help={errors?.amount.length > 0 ? errors.amount : undefined}
            label="Amount"
            name="amount"
            rules={[
              {
                required: true,
                message: "Amount is required",
              },
            ]}
          >
            <Inputamount
              name="amount"
              updateField={updateField}
              placeHolder="Enter amount"
              currency="₦"
              isWholeNumber={true}
            />
          </Form.Item> */}

          <Form.Item
            validateStatus={errors?.duration.length > 0 ? "error" : undefined}
            help={errors?.duration.length > 0 ? errors.duration : undefined}
            label="Duration (days)"
            name="duration"
            rules={[
              {
                required: true,
                message: "Duration is required",
              },
            ]}
          >
            <Input placeholder="Duration in days" min="1" type="number" />
          </Form.Item>

          <Form.Item
            validateStatus={errors?.count.length > 0 ? "error" : undefined}
            help={errors?.count.length > 0 ? errors.count : undefined}
            label="How many time do you want to repay?"
            name="count"
            rules={[
              {
                required: true,
                message: "Input field is required",
              },
            ]}
          >
            <Inputselect
              placeHolder="How many time do you want to repay?"
              updateField={updateField}
              name={"count"}
              selectOptions={installmentsCount}
              optionHasValue
            />
          </Form.Item>

          {constructFormInputs()}

          <Form.Item>
            <div className="flex flex-col items-center justify-center mx-auto mt-0 mb-40">
              <Button
                htmlType="submit"
                className="mb-2"
                disabled={
                  loading || loadingLoanTypes || uploading || submitting
                }
                loading={loading}
              >
                Send Loan Request
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>

      {showSummary && errorData !== null && (
        <Modal
          open={showSummary}
          footer={null}
          onCancel={
            submitting
              ? undefined
              : () => {
                  setShowSummary(false);
                }
          }
          onOk={
            submitting
              ? undefined
              : () => {
                  setShowSummary(false);
                }
          }
          destroyOnClose
        >
          <div className="">
            <div>
              <h1 className="mb-2 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
                Request Loan Summary
              </h1>
            </div>

            <p className="mb-2 font-poppins">
              Here is a summary of your loan details{" "}
            </p>

            <div className="p-2 bg-appcolorlightgray">
              <div className="flex justify-between mb-4 font-poppins">
                <p className="w-[40%] font-semibold font-poppins">Amount</p>
                <p className="w-[60%] flex justify-end font-poppins">
                  ₦{commaNumber(errorData?.request?.amount)}
                </p>
              </div>

              <div className="flex justify-between mb-4 font-poppins">
                <p className="w-[40%] font-semibold font-poppins"> Duration</p>
                <p className="w-[60%] flex justify-end font-poppins">
                  {errorData?.request?.duration} days
                </p>
              </div>

              <div className="flex justify-between mb-4 font-poppins">
                <p className="w-[40%] font-semibold font-poppins">Rate</p>
                <p className="w-[60%] flex justify-end font-poppins">
                  {errorData?.request?.interest_rate}%
                </p>
              </div>

              <div className="flex justify-between mb-4 font-poppins">
                <p className="w-[40%] font-semibold font-poppins">
                  Interest Amount
                </p>
                <p className="w-[60%] flex justify-end font-poppins">
                  ₦{commaNumber(errorData?.request?.interest_amount)}
                </p>
              </div>

              <div className="flex justify-between mb-4 font-poppins">
                <p className="w-[40%] font-semibold font-poppins">
                  Repayment Amount
                </p>
                <p className="w-[60%] flex justify-end font-poppins">
                  ₦{commaNumber(errorData?.request?.repayment_amount)}
                </p>
              </div>

              <div className="flex justify-between mb-4 font-poppins">
                <p className="w-[40%] font-semibold font-poppins">
                  Repayment Date
                </p>
                <p className="w-[60%] flex justify-end font-poppins">
                  {moment(errorData?.request?.repayment_date).format("LL")}
                </p>
              </div>
            </div>

            <div>
              <Button
                htmlType="submit"
                className="mb-2 "
                disabled={submitting}
                loading={submitting}
                onClick={() => {
                  submitOffer();
                }}
              >
                Proceed
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {visible && currentDoc !== "" && (
        <Modal
          open={visible}
          footer={null}
          onCancel={() => {
            setVisible(false);
          }}
          onOk={() => {
            setVisible(false);
          }}
        >
          <div className="mb-4">
            <Button>
              <a href={currentDoc} download>
                Download Document
              </a>
            </Button>
          </div>
          <DocViewer
            documents={[
              {
                uri: currentDoc,
              },
            ]}
            activeDocument={{
              uri: currentDoc,
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default Requestloan;
